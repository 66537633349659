import { API_RESULT } from "./ResultDialog";

export function handleError(err : any)  {

    let result : number = API_RESULT.REQUEST_FAILURE;

    if (err.response) {
        // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答
        console.error("Res Error(status): ", err.response.status);
        console.error("Res Error(headers): ", err.response.data);
        console.error("Res Error(data): ", err.response.data);
        switch (err.response.status) {
            case 400:
                result = API_RESULT.REQUEST_FAILURE;
                break;
            case 500:
                result = API_RESULT.INTERNAL_ERROR;
                break;
        }
    } else if (err.request) {
        // リクエストは行われましたが、応答がありませんでした
        console.error("Req Error");
        console.error(err);
        console.error(err.request);
        result = API_RESULT.NO_ANSWER;
    } else {
        console.error("Error: ", err.message);
        result = API_RESULT.INTERNAL_ERROR;
    }

    return result;
}