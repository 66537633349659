import { useState } from "react";
import { Grid } from "@mui/material";
import BasicTable, { LocationTableData, ColumnData } from "../assets/BasicTable";
import Layout from "../templetes/Layout";
import Search from "../assets/Search";
import SearchButton from "../assets/SearchButton";
import ClearButton from "../assets/ClearButton";
import GetButton from "../assets/GetButton";
import UpdateButton from "../assets/UpdateButton";
import DeleteButton from "../assets/DeleteButton";
import RegisterButton from "../assets/RegisterButton";

// 初期データ(空)
const initialRows: LocationTableData[] = [
];

type LocationColumnsData  = {
    location_id: ColumnData,
    building_name: ColumnData,
    latitude: ColumnData,
    longitude: ColumnData
};

const columns : LocationColumnsData = {
    location_id: {
        align: "right",
        name: "ロケーションID",
        primary: true,
        digits: 50
    },
    building_name: {
        align: "left",
        name: "建物名",
        digits: 30
    },
    latitude: {
        align: "left",
        name: "緯度",
        type: "number",
        digits: 9,
        minVal: -180.00000,
        maxVal: 180.000000,
        step: 0.000001
    },
    longitude: {
        align: "left",
        name: "経度",
        type: "number",
        digits: 9,
        minVal: -180.00000,
        maxVal: 180.000000,
        step: 0.000001
    }
};

const url= "https://d0qbwwoh4k.execute-api.ap-northeast-1.amazonaws.com/dev/location";

const Location = () => {
    // テーブルの行
    const [rows, setRows] = useState<LocationTableData[]>(initialRows);
    const [orgRows, setOrgRows] = useState<LocationTableData[]>(initialRows);
    // チェック選択レコード
    const [checkedRows, setCheckedRows] = useState<LocationTableData[]>(initialRows);

    // 検索バーに入力された文字
    const [buildingNameSearched, setBuildingNameSearched] = useState<string>("");

    // 追加・クリア・検索ボタン有効/無効設定用
    const [isAuthenticated, setAuthenticated] = useState(true);

    return(
        <Layout title="Location">
            <Grid container spacing={2} columns={10} position={"relative"}>
                <Grid item xs={4}>
                    <Search
                        id={"building-name-search"}
                        searchRows={rows}
                        searched={buildingNameSearched}
                        setRows={setRows}
                        setSearched={setBuildingNameSearched}
                        label="建物名"
                    >
                    </Search>
                </Grid>
                <Grid item xs={1.5}>
                    <SearchButton
                        nameSearched={{building_name: buildingNameSearched}}
                        orgRows={orgRows}
                        setRows={setRows}
                        setCheckedRows={setCheckedRows}
                        isAuthenticated={isAuthenticated}
                    />
                </Grid>
                <Grid item xs={1.5}>
                </Grid>
                <Grid item xs={1.5}>
                    <ClearButton
                        setRows={setRows}
                        setCheckedRows={setCheckedRows}
                        setNameSearched={[setBuildingNameSearched]}
                        isAuthenticated={isAuthenticated}
                        setAuthenticated={setAuthenticated}
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <GetButton
                        url={url}
                        setRows={setRows}
                        setOrgRows={setOrgRows}
                        setCheckedRows={setCheckedRows}
                        setAuthenticated={setAuthenticated}
                    />
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={1.5}>
                </Grid>
                <Grid item xs={1.5}>
                    <UpdateButton
                        url={url}
                        columns={columns}
                        rows={rows}
                        setRows={setRows}
                        setOrgRows={setOrgRows}
                        checkedRows={checkedRows}
                        setCheckedRows={setCheckedRows}
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <DeleteButton
                        url={url}
                        columns={columns}
                        setRows={setRows}
                        orgRows={orgRows}
                        setOrgRows={setOrgRows}
                        checkedRows={checkedRows}
                        setCheckedRows={setCheckedRows}
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <RegisterButton
                        url={url}
                        columns={columns}
                        rows={rows}
                        setRows={setRows}
                        setOrgRows={setOrgRows}
                        isAuthenticated={isAuthenticated}/>
                </Grid>
                <Grid item xs={10}>
                    <BasicTable
                        columns={columns}
                        rows={rows}
                        hasCheckbox
                        checkedRows={checkedRows}
                        setCheckedRows={setCheckedRows}
                    />
                </Grid>
            </Grid>
        </Layout>
    )
};
export default Location;