import { useRef } from 'react'
import Layout from "../templetes/Layout";
import { Typography, Grid, Button } from "@mui/material";

import { styled } from '@mui/material/styles';

const AppStamp = () => {
    const ImgDiv = styled('div')(({ theme }) => ({
        textAlign: "center"
    }));

    // 自動再生
    const videoRef = useRef<HTMLVideoElement>(null);

    // リプレイ対応
    const StartReplay = () => {
        if (videoRef.current?.currentTime) {
            videoRef.current.currentTime = 0;
        }
        videoRef.current?.play();
    }

    return(
        <Layout title="AppStamp">
            <Grid container direction="column" spacing={2} alignItems="center">
                {/* タイトル */}
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        スタンプラリーアプリ
                    </Typography>
                </Grid>
                {/* スクリーンショット紹介 */}
                <Grid container direction="row" spacing={0} justifyContent="center">
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <ImgDiv>
                            <img src="../pictures/screenshot1.png" />
                        </ImgDiv>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <ImgDiv>
                            <img src="../pictures/screenshot2.png" />
                        </ImgDiv>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <ImgDiv>
                            <img src="../pictures/screenshot3.png" />
                        </ImgDiv>
                    </Grid>
                </Grid>
                <br/>
                <br/>

                {/* モバイルアプリ概要 */}
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        モバイルアプリ概要
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        テーマ: VPS（Google Geospetial）を使ったスランプラリー<br/>
                        フォーカスシステムズの本社近くを流れる目黒川沿いを巡るARスタンプラリーアプリをご紹介します。
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        あらかじめ設定されたスタンプポイント(スタンプが獲得できる場所)を行き、
                        専用のモバイルアプリをかざすと、スタンプを獲得するための情報バナーがAR表示され、
                        バナー内のボタンをクリックすることでスタンプを獲得することができます。
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        目指すべきスタンプポイントは、モバイルアプリ上の矢印がガイド(ナビゲーション機能)されるため、
                        矢印の方向に向かって進むことで最寄りのスタンプポイントを見つけることができます。
                    </Typography>
                </Grid>
                <br/>
                <br/>

                {/* 機能紹介 */}
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        機能紹介
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" align="center" color="textSecondary" paragraph>
                        ①スタンプラリー機能
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        モバイルアプリ上でバーチャルスタンプを集めることができます。<br/>
                        集めたスタンプはアプリ上で確認することができます。<br/>
                        スタンプを全て集めると嬉しい特典が！？
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" align="center" color="textSecondary" paragraph>
                        ②ナビゲーション機能
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        モバイルアプリ上で最寄りのスタンプポイントに対してAR矢印が案内してくれます。<br/>
                        アプリの画面上から目的地を設定すると、設定した目的地の方向をガイドします。
                    </Typography>
                </Grid>
                <Grid item>
                    <video controls muted ref={videoRef} width={'100%'} poster="../pictures/poc_stamp_poster.png">
                        <source src="../movies/scene1.mp4" type="video/mp4" />
                        <p>Your browser doesn't support HTML5 video.</p>
                    </video>
                    <Button onClick={StartReplay} children="replay" variant="contained" color="primary" />
                </Grid>
                <br/>
                <br/>

                {/* モバイルアプリ概要 */}
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        システム要件
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        iOS: v12以降（検証済み端末:iPhone14 pro）
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        Android: v17以降（検証済み端末:Sony Experia）
                    </Typography>
                </Grid>
                <br/>
                <br/>
            </Grid>
        </Layout>
    )
};
export default AppStamp;