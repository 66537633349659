import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment } from "@mui/material";

// 検索バーのコンポーネント
const Search = (props : any) => {
    const { id,             // 検索ID
            searchRows,     // 検索対象レコード
            searched,       // 検索文字列
            setRows,        // テーブルレコード更新関数
            setSearched,    // 検索文字列更新関数
            label           // 検索バー表示ラベル
          } = props;

    // // 検索文字によってテーブルの行をフィルター関数
    // const requestSearch = (searchedVal : string) => {
    //     if (searchedVal === "") {
    //         console.log(searchRows);
    //         setRows(searchRows);
    //     } else {
    //         if (id === "user-name-search") {
    //             const filteredRows = searchRows.filter((row : TableData) => {
    //                 return row.user_id.toLowerCase().includes(searchedVal.toLowerCase());
    //             });
    //             setRows(filteredRows);
    //         } else {
    //             const filteredRows = searchRows.filter((row : TableData) => {
    //                 return row.building_name.toLowerCase().includes(searchedVal.toLowerCase());
    //             });
    //             setRows(filteredRows);
    //         }
    //     }
    // };

    // 検索バーの文字が変化したときにフィルターを実行する関数
    const changeSearchedHandler = (event : any) => {
        setSearched(event.target.value);
//        requestSearch(event.target.value);
    };

    return (
        <div>
            <TextField
                id={id}
                label={label}
                variant="standard"
                value={searched}
                fullWidth
                onChange={(event) => changeSearchedHandler(event)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};
export default Search;
