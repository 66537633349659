import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Button } from "@mui/material";
import UpdateModal from "./UpdateModal";
import ResultDialog from "./ResultDialog";
import { API_RESULT } from "./ResultDialog";
import { handleError } from "./CrudError";

const RegisterButton = (props : any) => {
    const {url,               // アクセス対象URL
           columns,           // テーブルカラム
           rows,              // テーブルレコード
           setRows,           // テーブルレコード更新関数
           setOrgRows,        // 元テーブルレコード更新関数
           isAuthenticated    // ボタン有効/無効設定
          } = props;

    let initialRow = {};

    for (let column of Object.keys(columns)) {
        Object.assign(initialRow, {[column] : ""});
    }

    // 追加対象レコード
    const [newRow, setNewRow] = useState(initialRow);
    // 追加ダイアログ用
    const [openDialog, setOpenDialog] = useState(false);
    // 処理結果
    const [result, setResult] = useState<number>(API_RESULT.SUCCESS);
    // 追加結果ダイアログ用
    const [openResultDialog, setOpenResultDialog] = useState(false);

    const table = url.split('/').pop();

    let keyColumn : string = "";

    Object.keys(columns).map((column : string) => {
        let result = columns[column].hasOwnProperty("primary");
        if(result) {
            keyColumn = column;
        }
        return column;
    });

    // 追加・更新入力項目処理
    const handleRowChange = (column: string, value: string) => {
        let tmpRow;
    
        if (columns[column].type === "number") {
            tmpRow = {...newRow,
                      [column] : Number(value)};
        } else {
            tmpRow = {...newRow,
                      [column]: value };
        }
        setNewRow(tmpRow);
    };

    // レコードの追加処理
    const insertTable = () => {
        let key : any;
        let value : any;
        let filteredRows;
        let tmpRows;
        let obj : any = Object.assign({}, newRow);
    
        if (table === "location") {
            obj[keyColumn] = uuidv4();
        };

        Object.keys(columns).map((column : string) => {
            let result = columns[column].hasOwnProperty("primary");
            if(result) {
                keyColumn = column;
            }
            return column;
        });

        // 入力項目チェック
        console.log(obj);
        for ([key, value] of Object.entries(obj)) {
            if (value === "" || value === 0) {
                setResult(API_RESULT.PARAMETER_ERROR);
                setOpenResultDialog(true);
                return;
            } else if (Number.isNaN(value) || obj[key].length > columns[key].digits) {
                setResult(API_RESULT.INVALID_VALUE);
                setOpenResultDialog(true);
                return;
            } else if (value < columns[key].minVal || columns[key].maxVal < value) {
                setResult(API_RESULT.INVALID_VALUE);
                setOpenResultDialog(true); 
                return; 
            }
        }

        // 二重登録チェック
        filteredRows = rows.filter((row : any) => {
            if (row[keyColumn] === obj[keyColumn]) {
                return row;
            }
            return null;
        });
        if (filteredRows.length) {
            setResult(API_RESULT.DOUBLE_REGISTER);
            setOpenResultDialog(true);
            return;
        }

        axios.post(url, obj).then(res => {
            console.log(res);
            console.log(res.status);
            console.log(res.data);
            tmpRows = rows.slice();
            tmpRows.push(obj);
            tmpRows.sort((preRow : any, row : any) => preRow[keyColumn] < row[keyColumn] ? -1 : 1);
            setRows(tmpRows);
            setOrgRows(tmpRows);
            setNewRow(initialRow);
            setResult(API_RESULT.SUCCESS);
            setOpenResultDialog(true);
        }).catch((err) => {
            let result;
            result = handleError(err);
            setResult(result);
            setOpenResultDialog(true);
        })

        setOpenDialog(false);
    };

    const handleClose = () => {
        setNewRow(initialRow);
        setOpenDialog(false);
    };

    return (
        <>
            <Button fullWidth variant="contained" disabled={isAuthenticated} onClick={() => setOpenDialog(true)}>追加</Button>
            <UpdateModal
                table={table}
                columns={columns}
                ope="insert"
                handleRowChange={(colomn : any, value : string) => handleRowChange(colomn, value)}
                isOpen={openDialog}
                onOpen={insertTable}
                onClose={handleClose}
            />
            <ResultDialog ope="追加" rowNum={1} result={result} open={openResultDialog} setOpen={setOpenResultDialog}/>
        </>
    );
};
export default RegisterButton;