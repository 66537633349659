import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material";

export const API_RESULT = {
    SUCCESS:         0,
    PARAMETER_ERROR: 1,
    DOUBLE_REGISTER: 2,
    INVALID_VALUE:   3,
    REQUEST_FAILURE: 4,
    INTERNAL_ERROR:  5,
    NO_ANSWER:       6,
} as const;

export const CAUSE_MSG = [
    "",
    "エラー理由 : 入力項目が不足しています",
    "エラー理由 : 既に登録済みです",
    "エラー理由 : 入力値が無効です",
    "エラー理由 : リクエストが不正です",
    "エラー理由 : 内部エラーが発生しました",
    "エラー理由 : リクエストは行われましたが、応答がありませんでした",
] as const;

const ResultDialog = (props: any) => {
    const {ope,       // 操作種別(ログイン/追加/更新/削除/参照)
           rowNum,    // 操作対象レコード数
           result,    // 処理結果
           open,      // 処理結果ダイアログ開閉フラグ
           setOpen    // 処理結果ダイアログ開閉フラグ更新関数
          } = props;

    let resultMsg : string;
    if (result === 0 ) {
        resultMsg = `レコード${rowNum}件の${ope}に成功しました`;
    }
    else {
        if (rowNum !== 0 ) {
            resultMsg = `レコード${rowNum}件の${ope}に失敗しました`;
        }
        else {
            // ログイン・参照時
            resultMsg = `${ope}に失敗しました`;
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign={"center"}>
                {`${ope}結果`}
            </DialogTitle>
            <DialogContent sx={{textAlign: "center"}}>
                <DialogContentText>
                    {resultMsg}
                </DialogContentText>
                <DialogContentText>
                {CAUSE_MSG[result]}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}
export default ResultDialog;