import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Checkbox } from "@mui/material";
import { styled } from '@mui/material/styles';

const CustomTable = styled(Table)({
    minWidth: 650,
});

export interface TableData {
    user_id: string,
    user_name: string,
    time_stamp: string,
    latitude: number,
    longitude: number,
    building_name: string
}

export interface UserTableData {
    user_id: string,
    user_name: string,
    user_pw: string,
    user_age: number
}

export interface LocationTableData {
    location_id: string,
    building_name: string,
    latitude: number,
    longitude: number
};

export interface ColumnData {
    align?: string,
    name: string,
    type?: "password" | "number",
    primary?: boolean,
    digits: number,
    minVal?: number,
    maxVal?: number,
    step?: number
};

export const createData = (
    user_id: string,
    user_name: string,
    time_stamp: string,
    latitude: number,
    longitude: number,
    building_name: string
) => {
    const td:TableData = {
        user_id: user_id,
        user_name: user_name,
        time_stamp: time_stamp,
        latitude: latitude,
        longitude: longitude,
        building_name: building_name
    }
    return td;
};

// テーブルを表示するコンポーネント
const BasicTable = (props : any) => {
    const { columns,          // テーブルカラム
            rows,             // テーブル行
            hasCheckbox,      // チェックボックス有無判定用フラグ
            checkedRows,      // チェック済みレコード
            setCheckedRows,   // チェック済みレコード更新関数
          } = props;

    const isChecked = (row : any) => {
        return checkedRows.indexOf(row) !== -1
    }

    const onCheckboxChecked = (event : any, row : any, index : number) => {
        let tmpRows;
        
        tmpRows = checkedRows.slice();
        if (event.target.checked) {
            tmpRows.splice(index, 0, row);
            setCheckedRows(tmpRows);
        } else {
            tmpRows.splice(checkedRows.indexOf(row), 1);
            setCheckedRows(tmpRows);
        }
    }
        
    return (
        <TableContainer component={Paper}>
            <CustomTable aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {hasCheckbox &&
                            <TableCell>
                            </TableCell>}
                        {Object.keys(columns).map((column : string, index: number) => (
                            <TableCell key={index}>{columns[column].name}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any, index: number) => (
                        <TableRow key={index}>
                            {hasCheckbox &&
                                <TableCell>
                                    <Checkbox checked={isChecked(row)} onChange={(event) => onCheckboxChecked(event, row, index)} />
                                </TableCell>
                            }
                            {Object.keys(row).map((key : any, index: number) => ( 
                                <TableCell key={index} align={columns[key].align}>
                                    {columns[key].type === "password" ? '*'.repeat(row[key].length) : row[key]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
        </TableContainer>
    );
};
export default BasicTable;