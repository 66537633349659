import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";

const DeleteDialog = (props: any) => {
    const {deleteRows,    // 削除対象レコード
           isOpen,        // 削除ダイアログ開閉フラグ
           onOpen,        // 削除OKコールバック関数
           onClose        // 削除キャンセルコールバック関数
          } = props;

    const [open, setOpen] = useState(false);

    useEffect(() => setOpen(isOpen), [isOpen]);

    return (
        <Dialog open={open} PaperProps={{sx : {border: "solid", borderColor: "#9C27B0"}}}>
            <DialogTitle>
                {`以下のレコード${deleteRows.length}件を削除してもよろしいですか`}
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableBody>
                        {deleteRows.map((row : any, index : number) => (
                            <TableRow key={index}>
                                {Object.keys(row).map((key) => (
                                    key === "user_pw"
                                    ? <TableCell key={key}>{'*'.repeat(row[key].length)}</TableCell>
                                    : <TableCell key={key}>{row[key]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={() => onClose()}>キャンセル</Button>
                <Button variant={"contained"} onClick={() => onOpen()}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}
export default DeleteDialog;