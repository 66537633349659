import { Modal, TextField, Button, Grid, Typography } from "@mui/material";

export type Operation = "insert" | "update";

const UpdateModal = (props: any) => {
    const {table,              // 追加/更新対象テーブル名
           columns,            // 追加/更新対象テーブル列
           ope,                // 操作種別(追加/更新)
           handleRowChange,    // キーボード入力コールバック関数
           isOpen,             // 追加/更新モーダル開閉フラグ
           onOpen,             // 追加/更新ボタンコールバック関数
           onClose             // 戻るボタンコールバック関数
          } = props;

    let title: string;
    let ope_str : string;
    let disabled_flg : boolean;

    // テーブル判定
    if( table === "user") {
        title = "ユーザ";
    } else {
        title = "ロケーション";
    }

    // 操作判定
    if( ope === "insert") {
        ope_str = "追加";
        disabled_flg = false;
    } else {
        ope_str = "更新";
        disabled_flg = true;
    }

    return (
        <Modal open={isOpen} >
            <Grid container spacing={2}
                bgcolor={"white"}
                padding={4}
                position={"fixed"}
                width={"50%"}
                left={"50%"}
                top={"50%"}
                sx={{transform: "translateX(-50%) translateY(-50%)",}}
                border={"solid"}
                borderColor={"#9C27B0"}
            >
                <Grid item xs={12} textAlign={"center"}>
                    <Typography
                        variant="h6"
                        sx={{ flexGrow: 1 }}
                    >
                    {`${title}${ope_str}画面`}
                    </Typography>
                </Grid>
                {Object.keys(columns).map((key : any, index : number) => (
                    <Grid item xs={12} key={index}>
                        {(() => {
                            let disabled = false;
                            let value = undefined;
                
                            // チェックボックス、ロケーションID入力不可
                            if (key === "checkbox" || key === "location_id") {
                                return;
                            }
                
                            if (key === "user_id") {
                                if (disabled_flg) {
                                    disabled = true;
                                    value = props.value;
                                }
                            }
                
                            return <TextField fullWidth
                                              label={columns[key].name}
                                              disabled={disabled}
                                              type={columns[key].type}
                                              inputProps={{min: columns[key].minVal,
                                                           max: columns[key].maxVal,
                                                           step: columns[key].step}}
                                              value={value}
                                              onChange={(event) => handleRowChange(key, event.target.value)}>
                                   </TextField>
                        })()}
                    </Grid>
                ))}
                <Grid item xs={6} textAlign={"right"}>
                    <Button variant="contained" onClick={() => onOpen()}>{ope_str}</Button>
                </Grid>
                <Grid item xs={6} textAlign={"left"}>
                    <Button variant="contained" onClick={() => onClose()}>戻る</Button>
                </Grid>
            </Grid>
        </Modal>
    );
}
export default UpdateModal;