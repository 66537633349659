import React, { useState } from 'react'
import Layout from "../templetes/Layout";
import { Typography, Grid } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';

const userInformation = [
    {
        title : "初期画面",
        imageUrl : "../pictures/user1.png",
    },
    {
        title : "全件取得ボタン押下後の画面",
        imageUrl : "../pictures/user2_1.png",
    },
    {
        title : "1件選択状態(更新/削除ボタンが有効になる)",
        imageUrl : "../pictures/user2_2.png",
    },
    {
        title : "複数選択状態(更新ボタンは無効になる)",
        imageUrl : "../pictures/user2_3.png",
    },
    {
        title : "ユーザ追加ダイアログ(追加ボタン押下で表示)",
        imageUrl : "../pictures/user3.png",
    },
    {
        title : "ユーザ削除ダイアログ(削除ボタン押下で表示)",
        imageUrl : "../pictures/user4.png",
    },
    {
        title : "ユーザ変更ダイアログ(変更ボタン押下で表示)",
        imageUrl : "../pictures/user5.png",
    },
];

const locationInformation = [
    {
        title : "初期画面",
        imageUrl : "../pictures/location1.png",
    },
    {
        title : "全件取得ボタン押下後の画面",
        imageUrl : "../pictures/location2_1.png",
    },
    {
        title : "1件選択状態(更新/削除ボタンが有効になる)",
        imageUrl : "../pictures/location2_2.png",
    },
    {
        title : "複数選択状態(更新ボタンは無効になる)",
        imageUrl : "../pictures/location2_3.png",
    },
    {
        title : "ロケーション追加ダイアログ(追加ボタン押下で表示)",
        imageUrl : "../pictures/location3.png",
    },
    {
        title : "ロケーション削除ダイアログ(削除ボタン押下で表示)",
        imageUrl : "../pictures/location4.png",
    },
    {
        title : "ロケーション変更ダイアログ(変更ボタン押下で表示)",
        imageUrl : "../pictures/location5.png",
    },
];

const statisticsInformation = [
    {
        title : "初期画面",
        imageUrl : "../pictures/statistics1.png",
    },
    {
        title : "全件取得ボタン押下後の画面",
        imageUrl : "../pictures/statistics2.png",
    },
    {
        title : "ユーザ名フィルタ実施時の画面",
        imageUrl : "../pictures/statistics3_1.png",
    },
    {
        title : "建物名フィルタ実施時の画面",
        imageUrl : "../pictures/statistics3_2.png",
    },
    {
        title : "ユーザ名・建物名フィルタ実施時の画面",
        imageUrl : "../pictures/statistics3_3.png",
    },
];

const Help = () => {
    const [expanded, setExpanded] = useState('');

    const handleChange = (panel : string) => (event : any, newExpanded : boolean) => {
        setExpanded(newExpanded ? panel : 'false');
    };

    const SampleImg = styled('img')(({ theme }) => ({
        borderStyle: "solid",
        border: "1",
    }));

    return(
        <Layout title="Help">
            <Grid container direction="column" spacing={2} alignItems="center">
                {/* タイトル */}
                <Grid item sx={{minWidth: {xs: 300, sm: 500, md: 800, lg: 990}}}>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        Webアプリ概要
                    </Typography>
                </Grid>
                <Grid item sx={{minWidth: {xs: 300, sm: 500, md: 800, lg: 990}}}>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        モバイルアプリのユーザ管理および、スタンプポイントのロケーション管理といったモバイルアプリに必要な情報を管理するためのWebアプリケーションです。 (以降、Webアプリ)
                    </Typography>
                </Grid>
                <Grid item sx={{minWidth: {xs: 300, sm: 500, md: 800, lg: 990}}}>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        Webアプリでは、ユーザのスタンプ獲得情報が統計情報として閲覧することができます。<br/>
                        スタンプラリーを企画する方はこの統計情報をKPIとして利用することができます。
                    </Typography>
                </Grid>
                <br/>
                <br/>

                {/* 機能紹介 */}
                <Grid item sx={{minWidth: {xs: 300, sm: 500, md: 800, lg: 990}}}>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        機能紹介
                    </Typography>
                </Grid>

                {/* ①ユーザ管理機能 */}
                <Grid item sx={{minWidth: {xs: 300, sm: 500, md: 800, lg: 990}}}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                                ①ユーザ管理機能
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="column">
                                {userInformation.map(userInfo => (
                                    <>
                                        <Grid item>
                                            <Typography variant="h5" align="center" color="textSecondary" paragraph>
                                                {userInfo.title}
                                            </Typography>
                                        </Grid>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item>
                                                <SampleImg src={userInfo.imageUrl} sx={
                                                    {
                                                        width: {xs: 300, sm: 500, md: 800, lg: 990},
                                                        height: {xs: 200, sm: 350, md: 550, lg: 600}
                                                    }
                                                }/>
                                            </Grid> 
                                        </Grid>
                                    </>
                                ))
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <br/>
                <br/>

                {/* ②ロケーション管理機能 */}
                <Grid item sx={{minWidth: {xs: 300, sm: 500, md: 800, lg: 990}}}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                                ②ロケーション管理機能
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="column">
                                {locationInformation.map(locationInfo => (
                                    <>
                                        <Grid item>
                                            <Typography variant="h5" align="center" color="textSecondary" paragraph>
                                                {locationInfo.title}
                                            </Typography>
                                        </Grid>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item>
                                                <SampleImg src={locationInfo.imageUrl} sx={
                                                    {
                                                        width: {xs: 300, sm: 500, md: 800, lg: 990},
                                                        height: {xs: 200, sm: 350, md: 550, lg: 600}
                                                    }
                                                }/>
                                            </Grid> 
                                        </Grid>
                                    </>
                                ))
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <br/>
                <br/>

                {/* ③統計管理機能 */}
                <Grid item sx={{minWidth: {xs: 300, sm: 500, md: 800, lg: 990}}}>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                                ③統計管理機能
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="column">
                                {statisticsInformation.map(statisticsInfo => (
                                    <>
                                        <Grid item>
                                            <Typography variant="h5" align="center" color="textSecondary" paragraph>
                                                {statisticsInfo.title}
                                            </Typography>
                                        </Grid>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item>
                                                <SampleImg src={statisticsInfo.imageUrl} sx={
                                                    {
                                                        width: {xs: 300, sm: 500, md: 800, lg: 990},
                                                        height: {xs: 200, sm: 350, md: 550, lg: 600}
                                                    }
                                                }/>
                                            </Grid> 
                                        </Grid>
                                    </>
                                ))
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <br/>
                <br/>
            </Grid>
        </Layout>
    )
};
export default Help;