import { Button } from "@mui/material";

const SearchButton = (props : any) => {
    const {nameSearched,      // 検索文字列
           orgRows,           // テーブル元レコード
           setRows,           // テーブルレコード更新関数
           setCheckedRows,    // チェック選択レコード更新関数
           isAuthenticated    // ボタン有効/無効設定
          } = props;


    // 検索処理
    const searchTable = () => {
        let filteredRows : any;

        // 検索バーがどちらも空で検索ボタン押下
        for (let [key, value] of Object.entries(nameSearched) ) {
            if( value !== "" ) {
                if (filteredRows) {
                    filteredRows = filteredRows.filter((row : any) => {
                        return row[key].toLowerCase().includes(String(value).toLowerCase());
                    });
                } else {
                    filteredRows = orgRows.filter((row : any) => {
                        return row[key].toLowerCase().includes(String(value).toLowerCase());
                    });
                }
            }
        }

        if (filteredRows) {
            setCheckedRows([]);
            setRows(filteredRows);
        } else {
            setRows(orgRows);
        }
    };

    return (
        <Button fullWidth variant="contained" disabled={isAuthenticated} onClick={searchTable}>検索</Button>
    );
}
export default SearchButton;