import { Navigate } from "react-router-dom";

const AuthenticatedGuard = (props : any) => {
    const authority = sessionStorage.getItem('AUTHORITY');
    if (authority === "OK") {
        return props.component;
    } 

    return <Navigate to="/" replace />
};
export default AuthenticatedGuard;