import { useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import UpdateModal from "./UpdateModal";
import ResultDialog from "./ResultDialog";
import { API_RESULT } from "./ResultDialog";
import { handleError } from "./CrudError";

const UpdateButton = (props : any) => {
    const {url,              // アクセス対象URL
           columns,          // テーブル列
           rows,             // テーブルレコード
           setRows,          // テーブルレコード更新関数
           setOrgRows,       // 元テーブルレコード更新関数
           checkedRows,      // チェック選択レコード
           setCheckedRows    // チェック選択レコード更新関数
          } = props;

    let initialRow = {};
    for (let column of Object.keys(columns)) {
        Object.assign(initialRow, {[column] : ""});
    }

    // 更新対象レコード
    const [newRow, setNewRow] = useState(initialRow);
    // 更新ダイアログ用
    const [openDialog, setOpenDialog] = useState(false);
    // 更新結果ダイアログ用
    const [openResultDialog, setOpenResultDialog] = useState(false);
    // 処理結果
    const [result, setResult] = useState<number>(API_RESULT.SUCCESS);

    // 追加・更新入力項目処理
    const handleRowChange = (column: string, value: string) => {
        let tmpRow;

        if (columns[column].type === "number") {
            tmpRow = {...newRow,
                      [column] : Number(value)};
        } else {
            tmpRow = {...newRow,
                      [column]: value };
        }
        
        setNewRow(tmpRow);
    };

    // レコードの更新
    const updateTable = () => {
        let key : string;
        let value : any;
        let tmpRows : any;
        let obj : any = Object.assign({}, newRow);
    
        obj[keyColumn] = checkedRows[0][keyColumn];
        // 入力項目チェック
        for ([key, value] of Object.entries(obj)) {
            if (value === "" || value === 0) {
                setResult(API_RESULT.PARAMETER_ERROR);
                setOpenResultDialog(true);
                return;
            } else if (Number.isNaN(value) || obj[key].length > columns[key].digits) {
                setResult(API_RESULT.INVALID_VALUE);
                setOpenResultDialog(true);
                return;
            } else if (value < columns[key].minVal || columns[key].maxVal < value) {
                setResult(API_RESULT.INVALID_VALUE);
                setOpenResultDialog(true); 
                return; 
            }
        }

        axios.put(url, obj).then((res) => {
            console.log(res);
            console.log(res.status);
            console.log(res.data);
            tmpRows = rows.slice();

            Object.keys(columns).map((column) => {
                tmpRows.map((row : any, index : number) => {
                    if (row[keyColumn] === obj[keyColumn]) {
                        row[column] = obj[column];
                    }
                    return row;
                });
                return column;
            });

            setCheckedRows([]);
            setRows(tmpRows);
            setOrgRows(tmpRows);
            setNewRow(initialRow);
            setResult(API_RESULT.SUCCESS);
            setOpenResultDialog(true);
        }).catch((err) => {
            let result;
            result = handleError(err);
            setResult(result);
            setOpenResultDialog(true);
        })

        setOpenDialog(false);
    };

    // 更新処理キャンセル
    const handleClose = () => {
        setNewRow(initialRow);
        setOpenDialog(false);
    }

    // 更新ボタン有効/無効設定
    const isAuthenticated = (checkedRows.length !== 1);
    let keyColumn : string = "";

    Object.keys(columns).map((column : string) => {
        let result = columns[column].hasOwnProperty("primary");
        if(result) {
            keyColumn = column;
        }
        return column;
    });

    return (
        <>
            <Button fullWidth variant="contained" disabled={isAuthenticated} onClick={() => setOpenDialog(true)}>更新</Button>
            <UpdateModal
                table="user"
                columns={columns}
                ope="update"
                value={!isAuthenticated ? checkedRows[0][keyColumn] : ""}
                handleRowChange={(colomn : any, value : string) => handleRowChange(colomn, value)}
                isOpen={openDialog}
                onOpen={updateTable}
                onClose={handleClose}
            />
            <ResultDialog ope="更新" rowNum={1} result={result} open={openResultDialog} setOpen={setOpenResultDialog}/>
        </>
    );
};
export default UpdateButton;