import { Link } from "react-router-dom";
import { Avatar, IconButton} from '@mui/material';
import { Button, Typography } from "@mui/material";
import { Card, CardHeader, CardMedia, CardContent, CardActions } from "@mui/material";
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { styled } from '@mui/material/styles';

const BodyCard = (props : any) => {
    const {
            avatarUrl,          // アバターURL
            imageUrl,           // イメージURL
            mediaTitle,         // 画像タイトル文字列
            headerTitle,        // ヘッダータイトル文字列
            headerSub,          // ヘッダーサブ文字列
            contentText,        // コンテンツ文字列
            linkPath,           // リンクパス
    } = props;

    // style
    const SampleCard = styled(Card)(({ theme }) => ({
        height: "100%",
        display: "flex",
        flexDirection: "column",
    }));
    const SampleCardMedia = styled(CardMedia)(({ theme }) => ({
        paddingTop: "100%",
    }));
    const SampleCardContent = styled(CardContent)(({ theme }) => ({
        flexGrow: 1,
    }));

    return (
        <SampleCard>
            <CardHeader title={headerTitle} subheader={headerSub}
                        avatar={
                            /*<Avatar aria-label="recipe">R</Avatar>*/
                            <Avatar src={avatarUrl} />
                        }
                        action={
                            <IconButton aria-label="settings">
                                <StarBorderOutlinedIcon />
                            </IconButton>
                        }
            >
            </CardHeader>
            <SampleCardMedia image={imageUrl} title={mediaTitle} />              
            <SampleCardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {contentText}
                </Typography>
            </SampleCardContent>
            <CardActions>
                <Button size="small" color="primary" component={Link} to={linkPath}>View</Button>
            </CardActions>
        </SampleCard>
    );
};
export default BodyCard;