import { useState } from 'react';
import Layout from "../templetes/Layout";
import "@google/model-viewer/dist/model-viewer";
import { TextField, Typography, Grid } from "@mui/material";


declare global {
    namespace JSX {
        interface IntrinsicElements {
            'model-viewer': ModelViewerJSX;
        }
        interface ModelViewerJSX {
            src: string;
            poster: string;
            alt: string;
            ar: boolean;
            exposure: number;
            scale: string;
        }
    }
}

const WebAR = (props : any) => {
    var alt = "pizza";
    var src = "../assets/pizza1.glb";
    var iossrc = "../assets/pizza1.usdz";
    var poster = "../assets/pizza1.png";

    const [exposure, setExposure] = useState(1);
    const [shadowIntensity, setShadowIntensity] = useState(1);
    const [scale, setScale] = useState(1);

    return(
        <Layout title="WebAR">
            <Grid container direction="column" spacing={2} alignItems="center">
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        WebAR Sample
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        google/model-viewerを使ったWebARのサンプルです。<br/>
                        スマホからアクセスしてみてください。
                    </Typography>
                </Grid>
                <Grid item>
                    <model-viewer
                        src={src}                               // Android向けのglbファイル/gltfファイルのパスを指定。
                        ios-src={iossrc}                        // iOS向けのusdzファイルのパスを指定。(省略するとgltf→usdzの自動変換)
                        poster={poster}                         // 3Dモデルが読み込まれてレンダリングの準備が整う前に表示される画像のファイルのパスを指定。
                        alt={alt}                               // 画像の表示内容を補足するためのテキスト
                        camera-controls                         // タップによるカメラ操作を許可。
                        ar                                      // ARとして使用することを明示
                        auto-rotate                             // Webブラウザ上にて、3Dモデルの自動回転を許可。
                        exposure={exposure}                     // 3Dモデルが表示されるときの明るさを調整するため属性
                        shadow-intensity={shadowIntensity}      // 照明による陰影の強度を調整するための属性
                        scale={`${scale} ${scale} ${scale}`}    // 3DオブジェクトのXYZ軸でのスケールサイズ
                    ></model-viewer>
                </Grid>
                <br/>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <TextField 
                            fullWidth
                            label="shadow-intensity"
                            type="number"
                            inputProps={{
                                        min: 0,
                                        max: 100,
                                        step: 1}}
                            value={shadowIntensity}
                            onChange={(e : any) => setShadowIntensity(e.target.value)}
                            >
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField 
                            fullWidth
                            label="exposure"
                            type="number"
                            inputProps={{
                                        min: 0,
                                        max: 5,
                                        step: 0.1}}
                            value={exposure}
                            onChange={(e : any) => setExposure(e.target.value)}
                            >
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField 
                            fullWidth
                            label="scale"
                            type="number"
                            inputProps={{
                                        min: 1,
                                        max: 3,
                                        step: 1}}
                            value={scale}
                            onChange={(e : any) => setScale(e.target.value)}
                            >
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    )
};
export default WebAR;