import React, { useRef, useEffect } from 'react'
import Layout from "../templetes/Layout";
import BodyCard from "../assets/BodyCard";
import { Button, Typography, Grid } from "@mui/material";
import TitlebarImageList from "../assets/TitlebarImageList";
import { styled } from '@mui/material/styles';

// アプリ情報(Cardに表示する内容)
const cardContents = [
    {
        avatarUrl : "../pictures/avatar_stamp.png",
        imageUrl : "../pictures/thumbnail_stamp.png",
        mediaTitle : "screenshot1",
        headerTitle : "ARスタンプラリーアプリ",
        headerSub : "リリース日:2023/10/31",
        contentText : "VPSを使用したARスタンプラリーアプリです。五反田周辺の目黒川沿いを巡るスタンプラリーを体験してみませんか？",
        linkPath : "/appstamp",
    },
    {
        avatarUrl : "",
        imageUrl : "../pictures/thumbnail_webar.png",
        mediaTitle : "screenshot2",
        headerTitle : "WebARアプリ",
        headerSub : "リリース日:2023/10/31",
        contentText : "model-viewerを使用したWebARアプリです。スマホでアクセスしてみてください。",
        linkPath : "/webar",
    },
    {
        avatarUrl : "",
        imageUrl : "../pictures/thumbnail_arcard.png",
        mediaTitle : "screenshot3",
        headerTitle : "AR名刺サービス",
        headerSub : "リリース日:2023/11/30",
        contentText : "Webブラウザで完結するAR名刺サービスです。",
        linkPath : "/arcard",
    },
];

// 写真一覧
const photoImages = [
    {
        img: '../pictures/photo1.jpeg',
        title: '五反田',
        subtitle: 'スタンプラリー会場',
    },
    {
        img: '../pictures/photo2.jpeg',
        title: 'テラサキ第３ビル',
        subtitle: '出発地点',
    },
    {
        img: '../pictures/photo3.jpeg',
        title: '五反田大橋',
        subtitle: '第1スタンプポイント',
    },
    {
        img: '../pictures/photo4.jpeg',
        title: '大崎橋広場',
        subtitle: '第2スタンプポイント',
    },
    {
        img: '../pictures/photo5.jpeg',
        title: '五反田南公園',
        subtitle: '第3スタンプポイント',
    },
    {
        img: '../pictures/photo6.jpeg',
        title: '五反田ふれあい水辺広場',
        subtitle: '第4スタンプポイント',
    },
    {
        img: '../pictures/photo7.jpeg',
        title: 'フォーカス五反田ビル',
        subtitle: 'ゴール地点',
    },
    {
        img: '../pictures/photo8.jpeg',
        title: 'スタンプラリー実施中',
        subtitle: '検証者',
    },
];

const Home = () => {
    // style
    const SampleHr = styled('hr')(({ theme }) => ({
        position: "relative",
        height: "3px",
        backgroundColor: "#00bcd4",
        backgroundImage: "-webkit-linear-gradient( 135deg, #FD6585 10%, #0D25B9 100%)",
    }));

    const Img = styled('img')(({ theme }) => ({
    }));

    // Card作成用ロジック
    const getCardContent = (getObj : any) => {
        return (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <BodyCard {...getObj} />
            </Grid>
        );
    };

    // 自動再生
    const videoRef = useRef<HTMLVideoElement>(null);
    /*
    useEffect(() => {
        videoRef.current?.play();
    }, []);
    */

    // リプレイ対応
    const StartReplay = () => {
        if (videoRef.current?.currentTime) {
            videoRef.current.currentTime = 0;
        }
        videoRef.current?.play();
    }

    return(
        <Layout title="Home">
            <Grid container direction="column" spacing={2} alignItems="center">
                {/* メイン動画 */}
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        What's new?
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        2023.10.31 ARスタンプラリーアプリをリリースしました。
                    </Typography>
                </Grid>
                <Grid item>
                    <video controls muted ref={videoRef} width={'100%'} poster="../pictures/corporate_slogan.png">
                        <source src="../movies/3d-route.webm" type="video/webm" />
                        <p>Your browser doesn't support HTML5 video.</p>
                    </video>
                    <Button onClick={StartReplay} children="replay" variant="contained" color="primary" />
                </Grid>
                <br/>
                <br/>

                {/* アプリケーション紹介 */}
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        Applications
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        Last Update: 2023/11/30
                    </Typography>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                    {cardContents.map(contentObj => getCardContent(contentObj))}
                </Grid>
                <br/>
                <br/>

                {/* PoC (Proof of Concept) */}
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        PoC (Proof of Concept)
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        弊社で制作したARスタンプラリーアプリを使用して実証実験を実施しました。<br />
                    </Typography>
                    <br/>
                    <Grid item>
                        <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                            Overview
                        </Typography>
                    </Grid>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        実施場所：五反田、目黒川周辺<br/>
                        実施人数:4名<br/>
                        実施日:2023/10/31
                    </Typography>
                </Grid>
                <Grid item>
                    <Img src="../pictures/poc_routemap.png" sx={
                                {
                                    width: {xs: 300, sm: 500, md: 800, lg: 990},
                                    height: {xs: 200, sm: 350, md: 550, lg: 600}
                                }
                            }/>
                </Grid>
                <br/>
                <br/>

                {/* Architecture */}
                <Grid item>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                        Architecture
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        ARスタンプラリーアプリのアーキテクチャは下記のとおりです。
                    </Typography>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <Img src="../pictures/architecture.png" sx={
                                {
                                    width: {xs: 300, sm: 500, md: 800, lg: 990},
                                    height: {xs: 200, sm: 350, md: 550, lg: 600}
                                }
                            }/>
                    </Grid>           
                </Grid>
                <br/>
                <br/>

                {/* Photography of PoC */}
                <Grid item>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                        Photography of PoC
                    </Typography>
                </Grid>
                <Grid item>
                    <TitlebarImageList
                        photoImages={photoImages}
                        headerTitle="2023.10.31 ARスタンプラリアプリ"
                    />
                </Grid>
                <Grid item>
                    <video controls muted ref={videoRef} width={'100%'} poster="../pictures/ok_ng_poster.png">
                        <source src="../movies/scene2.mp4" type="video/mp4" />
                        <p>Your browser doesn't support HTML5 video.</p>
                    </video>
                    <Button onClick={StartReplay} children="replay" variant="contained" color="primary" />
                </Grid>
                <br/>
                <br/>

                {/* Contact */}
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        Contact
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        本件に関するお問い合わせは下記までご連絡ください。
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        E-mail : xxxxx@xxxxxx<br/>
                        Tel : 03-xxxx-xxxx<br/>
                        Department name : IST
                    </Typography>
                </Grid>
                <br/>
                <Grid item>
                    <Img src="../pictures/corporate_slogan.png" sx={
                                {
                                    width: {xs: 300, sm: 500, md: 700, lg: 800},
                                    height: {xs: 200, sm: 350, md: 500, lg: 600}
                                }
                            }/>
                </Grid>
            </Grid>
            <SampleHr/>
            <SampleHr/>
            <SampleHr/>
        </Layout>
    )
};
export default Home;