import { Button } from "@mui/material";

const ClearButton = (props : any) => {
    const {setRows,            // テーブル行更新関数
           setCheckedRows,     // チェック済みレコード更新関数
           setNameSearched,    // 検索文字列更新関数
           isAuthenticated,    // ボタン有効/無効設定
           setAuthenticated    // ボタン有効/無効設定更新関数
          } = props;

    // テーブル、検索ボックスのクリア
    const clearTable = () => {
        setCheckedRows([]);
        setRows([]);
        for (let setName of setNameSearched) {
            setName("");
        }

        setAuthenticated(true);
    };

    return (
        <Button fullWidth variant="contained" disabled={isAuthenticated} onClick={clearTable}>クリア</Button>
    );
};
export default ClearButton;