import axios from "axios";
import { useState } from "react";
import { Typography, Box, TextField, Button, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { handleError } from "../assets/CrudError";
import { API_RESULT } from "../assets/ResultDialog";
import ResultDialog from "../assets/ResultDialog";

//型宣言
type Inputs = {
    user_id: string;
    user_pw: string;
}

const url= "https://d0qbwwoh4k.execute-api.ap-northeast-1.amazonaws.com/dev/login";

const Login = () => {
    const [inputData, setInputData] = useState<Inputs>({user_id: "", user_pw: ""});
    const [errMsg, setErrMsg] = useState<string>("");
    const navigate = useNavigate();
    // 処理結果
    const [result, setResult] = useState<number>(API_RESULT.SUCCESS);
    // 取得結果ダイアログ用
    const [openResultDialog, setOpenResultDialog] = useState(false);

    // 認証処理
    const authenticate = () => {
        let result : number; 

        axios.post(url, inputData).then((res) => {
            result = Number(res.data);
            if ( !Number.isNaN(result) && result !== 0 ) {
                loginSuccess();
            } else {
                loginFailure();
            }
        }).catch((err) => {
            let result;
            result = handleError(err);
            setResult(result);
            setOpenResultDialog(true);     
        })

        return;
    };

    const handleChange = (event : any) => {
        let tmpData : any = Object.assign({}, inputData);
        tmpData[event.target.name] = event.target.value;
        setInputData(tmpData);
    }

    const handleClick = () => {
        if (inputData.user_id === "") {
            setErrMsg("ユーザ名を入力してください");
            return;
        } else if (inputData.user_pw === "") {
            setErrMsg("パスワードを入力してください");
            return;
        }

        authenticate();
    }

    const loginSuccess = () => {
        sessionStorage.setItem('AUTHORITY', "OK");
        navigate('/home');
    }

    const loginFailure = () => {
        setErrMsg("ユーザ名かパスワードが不正です。");
    }

    return (
        <>
            <Box textAlign={"center"} sx={{margin: "0 auto",
                                           padding: "100px",
                                           position: "relative",
                                           verticalAlign: "middle"}}>
                <Box sx={{padding: "0 auto", marginTop: "20px"}}>
                    <Typography component="h5" variant="h5" align="center">ログイン画面</Typography>
                    <hr/>
                </Box>
                <Box>
                    <Box sx={{position: "relative",
                         borderRadius: "10px",
                         backgroundColor: "#fafafa",
                         marginTop: "30px",
                         }}>
                        {errMsg && <Alert severity="error" sx={{color: "#f00"}}>{errMsg}</Alert>}
                        <TextField
                            fullWidth
                            label="ユーザ名"
                            InputLabelProps={{shrink: true, sx: {fontWeight: 700, marginTop: "30px", padding: "40px auto"}}}
                            name="user_id"
                            margin="normal"
                            onChange={handleChange}
                            InputProps={{sx: {backgroundColor: "#fff", marginTop: "30px"}}}
                            sx={{width: "80%", display: "block", margin: "0 auto 20px", padding: "40px auto"}}
                        />
                        <TextField
                            fullWidth
                            type="password"
                            label="パスワード"
                            InputLabelProps={{shrink: true, sx: {fontWeight: 700, padding: "20px auto"}}}
                            name="user_pw"
                            margin="normal"
                            onChange={handleChange}
                            InputProps={{sx: {backgroundColor: "#fff"}}}
                            sx={{width: "80%", display: "block", margin: "20px auto 20px", padding: "20px auto"}}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleClick}
                            sx={{width: "80%", margin: "0 auto", padding: "20px", marginBottom: "40px", marginTop: "10px"}}>
                        ログイン
                        </Button>
                        <ResultDialog ope="ログイン" rowNum={0} result={result} open={openResultDialog} setOpen={setOpenResultDialog}/>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default Login;