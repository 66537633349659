import { Modal, Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ImageModal = (props : any) => {
    const {
        isOpen,                 // モーダルウィンドウを開くか閉じるか(true/false)
        imgUrl,                 // 表示画像パス
        setOpenedImg,           // 親のstate設定メソッド(モーダルウィンドウ閉じる時に「空文字」を設定する)
    } = props;

    const Img = styled('img')(({ theme }) => ({
    }));

    // 閉じるボタン押下時のイベントハンドラ
    const handleClose = () => {
        setOpenedImg('');
    }

    // モーダル背景を押下時のイベントハンドラ
    const handleClose2 = (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
        //if ( reason === 'backdropClick') return;
        setOpenedImg('');
    };

    return (
        <Modal open={isOpen} onClose={handleClose2}>
            <Grid container spacing={2}
                justifyContent={"center"}
                bgcolor={"white"}
                padding={2}
                position={"fixed"}
                width={"75%"}
                left={"50%"}
                top={"50%"}
                sx={{transform: "translateX(-50%) translateY(-50%)",}}
                border={"solid"}
                borderColor={"#9C27B0"}
            >
                <Grid item xs={12} textAlign={"center"}>
                    <Img src={imgUrl} sx={
                        {
                            width: {xs: 240, sm: 400, md: 512, lg: 720},
                            height: {xs: 180, sm: 300, md: 384, lg: 540}
                        }
                    }/>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                    <Button variant="contained" onClick={() => handleClose()}>閉じる</Button>
                </Grid>
            </Grid>
        </Modal>
    );
};
export default ImageModal;