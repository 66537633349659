import {BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './component/pages/Login';
import Home from './component/pages/Home';
import AppStamp from './component/pages/AppStamp';
import WebAR from './component/pages/WebAR';
import ARCard from './component/pages/ARCard';
import Statistics from './component/pages/Statistics';
import User from './component/pages/User';
import Location from './component/pages/Location';
import Help from './component/pages/Help';
import AuthenticatedGuard from './component/AuthenticatedGuard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/home' element={<AuthenticatedGuard component={<Home />} />} />
        <Route path='/statistics' element={<AuthenticatedGuard component={<Statistics />} />} />
        <Route path='/user' element={<AuthenticatedGuard component={<User />} />} />
        <Route path='/location' element={<AuthenticatedGuard component={<Location />} />} />
        <Route path='/appstamp' element={<AuthenticatedGuard component={<AppStamp />} />} />
        <Route path='/help' element={<AuthenticatedGuard component={<Help />} />} />
        <Route path='/webar' element={<AuthenticatedGuard component={<WebAR />} />} />
        <Route path='/arcard' element={<AuthenticatedGuard component={<ARCard />} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
