import { useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { useMediaQuery } from "@mui/material";
import ImageModal from "../assets/ImageModal";

export interface ImageListItemData {
    img: string,
    title: string,
    subtitle: string,
    rows: number,
    cols: number,
    featured: boolean
}

const TitlebarImageList = (props : any) => {
    const {
        photoImages,           // 画像情報の配列
        headerTitle,           // ヘッダータイトル文字列
    } = props;

    // 画面横幅が900px以上でtrue, 899px以下でfalse
    const isPC = useMediaQuery("(min-width:900px)");

    // イメージモーダルの表示状態を管理するstate（閉じる操作は子コンポーネント側で制御）
    const [openedImg, setOpenedImg] = useState('');
    const handleOpen = (key : string) => {
      setOpenedImg(key);
    }

    return (
        <ImageList sx={
              {
                // 端末によってサイズを変える
                width: {xs: 300, sm: 500, md: 800, lg: 990}, 
                maxHeight: {xs: 300, sm: 450, md: 500, lg: 500}
              }
            }  cols={isPC ? 3 : 2 }>
            <ImageListItem key="Subheader" cols={isPC ? 3 : 2 }>
                <ListSubheader component="div">{headerTitle}</ListSubheader>
            </ImageListItem>
            {photoImages.map((photoImage : ImageListItemData) => (
                <ImageListItem key={photoImage.img}>
                    <img
                        srcSet={`${photoImage.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        src={`${photoImage.img}?w=248&fit=crop&auto=format`}
                        alt={photoImage.title}
                        loading="lazy"
                    />
                    <ImageListItemBar
                        title={photoImage.title}
                        subtitle={photoImage.subtitle}
                        actionIcon={
                            <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`info about ${photoImage.title}`}
                                onClick={() => handleOpen(photoImage.img)}
                            >
                            <InfoIcon />
                            </IconButton>
                        }
                    />
                    {/* 拡大画像を表示するモーダルウィンドウ */}
                    <ImageModal isOpen={openedImg === photoImage.img} imgUrl={photoImage.img} setOpenedImg={setOpenedImg} />
                </ImageListItem>
            ))}
        </ImageList>
    );
}

export default TitlebarImageList;