import { useState } from "react";
import Layout from "../templetes/Layout";
import Search from "../assets/Search";
import { Grid } from "@mui/material";
import SearchButton from "../assets/SearchButton";
import ClearButton from "../assets/ClearButton";
import GetButton from "../assets/GetButton";
import UpdateButton from "../assets/UpdateButton";
import DeleteButton from "../assets/DeleteButton";
import RegisterButton from "../assets/RegisterButton";
import BasicTable,  { UserTableData, ColumnData} from "../assets/BasicTable";

// 初期データ(空)
const initialRows: UserTableData[] = [
];

type UserColumnsData = {
    user_id: ColumnData,
    user_name: ColumnData,
    user_pw: ColumnData,
    user_age: ColumnData
};

const columns : UserColumnsData = {
    user_id: {
        align: "right",
        name: "ユーザID",
        primary: true,
        digits: 50
    },
    user_name: {
        align: "left",
        name: "ユーザ名",
        digits: 20
    },
    user_pw: {
        align: "left",
        name: "パスワード",
        type: "password",
        digits: 20
    },
    user_age: {
        align: "left",
        name: "年齢",
        type: "number",
        digits: 3,
        minVal: 1,
        maxVal: 100
    }
};

const url= "https://d0qbwwoh4k.execute-api.ap-northeast-1.amazonaws.com/dev/user";

const User = () => {
    // テーブルの行
    const [rows, setRows] = useState<UserTableData[]>(initialRows);
    const [orgRows, setOrgRows] = useState<UserTableData[]>(initialRows);
    // チェック選択レコード
    const [checkedRows, setCheckedRows] = useState<UserTableData[]>(initialRows);

    // 検索バーに入力された文字
    const [userNameSearched, setUserNameSearched] = useState<string>("");

    // 追加・クリア・検索ボタン有効/無効設定用
    const [isAuthenticated, setAuthenticated] = useState(true);  

    return(
        <Layout title="User">
            <Grid container spacing={2} columns={10} position={"relative"}>
                <Grid item xs={4}>
                    <Search
                        id={"user-name-search"}
                        searchRows={rows}
                        searched={userNameSearched}
                        setRows={setRows}
                        setSearched={setUserNameSearched}
                        label="ユーザ名"
                    >
                    </Search>
                </Grid>
                <Grid item xs={1.5}>
                    <SearchButton  
                        nameSearched={{user_name: userNameSearched}}
                        orgRows={orgRows}
                        setRows={setRows}
                        setCheckedRows={setCheckedRows}
                        isAuthenticated={isAuthenticated}
                    />
                </Grid>
                <Grid item xs={1.5}>
                </Grid>
                <Grid item xs={1.5}>
                    <ClearButton rows={rows}
                                 setRows={setRows}
                                 setCheckedRows={setCheckedRows}
                                 setNameSearched={[setUserNameSearched]}
                                 isAuthenticated={isAuthenticated}
                                 setAuthenticated={setAuthenticated}
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <GetButton url={url} 
                               setRows={setRows}
                               setOrgRows={setOrgRows}
                               setCheckedRows={setCheckedRows}
                               setAuthenticated={setAuthenticated}
                    />
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={1.5}>
                </Grid>
                <Grid item xs={1.5}>
                    <UpdateButton url={url}
                                  columns={columns}
                                  rows={rows}
                                  setRows={setRows}
                                  setOrgRows={setOrgRows}
                                  checkedRows={checkedRows}
                                  setCheckedRows={setCheckedRows}
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <DeleteButton url={url}
                                  columns={columns}
                                  setRows={setRows}
                                  orgRows={orgRows}
                                  setOrgRows={setOrgRows}
                                  checkedRows={checkedRows}
                                  setCheckedRows={setCheckedRows}/>
                </Grid>
                <Grid item xs={1.5}>
                    <RegisterButton url={url}
                                    columns={columns}
                                    rows={rows}
                                    setRows={setRows}
                                    setOrgRows={setOrgRows}
                                    isAuthenticated={isAuthenticated}
                                    />
                </Grid>
                <Grid item xs={10}>
                    <BasicTable
                        columns={columns}
                        rows={rows}
                        hasCheckbox
                        checkedRows={checkedRows}
                        setCheckedRows={setCheckedRows}
                    />
                </Grid>
            </Grid>
        </Layout>
    )
};
export default User;