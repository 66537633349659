import Layout from "../templetes/Layout";
import { Typography, Grid } from "@mui/material";

const ARCard = () => {

    return(
        <Layout title="AR Card">
            <Grid container direction="column" spacing={2} alignItems="center">
                <Grid item>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        AR名刺サービス
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                        サービス概要
                    </Typography>
                    <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                        AR名刺サービスとは、Webブラウザで完結するAR名刺サービスです。<br/>
                        お手持ちの名刺をスマホで読み取ると、3DオブジェクトをAR表示できます。<br/>
                    </Typography>
                </Grid>
                <br/>
                <Grid item>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                        サービスコンセプト
                    </Typography>
                    <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
                        <strong>①使いやすい</strong><br/>
                        <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                            複雑な操作や事前のアプリインストールは一切不要!<br/>
                            スマホで名刺を読み取るだけで即サービスを利用できます。<br/>
                            ビジネスの場で名刺はポピュラーな営業ツールであり、<br/>
                            全社員が使用できるものなので使いやすさを優先しました。<br/>
                            <br/>
                        </Typography>
                        <strong>②導入しやすい</strong><br/>
                        <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                            1つのQRコードをサービスが完結!<br/>
                            QRコードをお手持ちの名刺に印刷するだけで、<br/>
                            すぐにサービス展開可能です。
                            <br/>
                        </Typography>
                    </Typography>
                </Grid>
                <br/>
                <Grid item>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                        ユースケース
                    </Typography>
                </Grid>
                <Grid item textAlign={"center"}>
                    <img src="../pictures/arcard_usecase.png" width={"70%"} height={"70%"}/>
                </Grid>
                <br/>
                <Grid item>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                        サービス利用手順
                    </Typography>
                    <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                        QRコードをスマホのカメラで読み込むとAR名刺サービスが起動します。<br/>
                    </Typography>
                </Grid>
                <Grid item textAlign={"center"}>
                    <img src="../pictures/qrcode_arcard.png" width={"20%"} height={"20%"}/>
                </Grid>
            </Grid>
        </Layout>
    )
};
export default ARCard;