import { useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import DeleteDialog from "./DeleteDialog";
import ResultDialog from "../assets/ResultDialog";
import { API_RESULT } from "../assets/ResultDialog";
import { handleError } from "./CrudError";

const DeleteButton = (props : any) => {
    const {url,              // アクセス対象URL
           columns,          // テーブルカラム
           setRows,          // テーブル行更新関数
           orgRows,          // 元テーブル行
           setOrgRows,       // 元テーブル行更新関数
           checkedRows,      // チェック済みレコード
           setCheckedRows    // チェック済みレコード更新関数
          } = props;

    // 削除ダイアログ用
    const [openDeleteDlg, setOpenDeleteDlg] = useState(false);
    // 削除結果ダイアログ用
    const [openDeleteResult, setOpenDeleteResult] = useState(false);
    // 処理結果
    const [result, setResult] = useState<number>(API_RESULT.SUCCESS);
    // データ件数
    const [rowNum, setRowNum] = useState(0);

    // レコードの削除
    const deleteTable = () => {
        let deleteUsers : string[] = [];
        let diffRows : [];

        checkedRows.map((row : any) => (
            deleteUsers.push(row[keyColumn])
        ))

        setRowNum(checkedRows.length);

        axios.delete(url, {data: {[keyColumn]: deleteUsers}}).then(res => {
            console.log(res);
            console.log(res.status);
            console.log(res.data);
            diffRows = orgRows.filter((row : any) => checkedRows.indexOf(row) === -1 );
            setCheckedRows([]);
            setRows(diffRows);
            setOrgRows(diffRows);
            setResult(API_RESULT.SUCCESS);
            setOpenDeleteResult(true);
        }).catch((err) => {
            let result;
            result = handleError(err);
            setResult(result);
            setOpenDeleteResult(true);
        })

        setOpenDeleteDlg(false);
    };

    // 更新ボタン有効/無効設定
    const isAuthenticated = (checkedRows.length === 0);
    let keyColumn : string = "";

    Object.keys(columns).map((column : string) => {
        let result = columns[column].hasOwnProperty("primary");
        if(result) {
            keyColumn = column;
        }
        return column;
    });

    return (
        <>
            <DeleteDialog
                deleteRows={checkedRows}
                isOpen={openDeleteDlg}
                onOpen={() => deleteTable()}
                onClose={() => setOpenDeleteDlg(false)}
            />
            <Button fullWidth variant="contained" disabled={isAuthenticated} onClick={() => setOpenDeleteDlg(true)}>削除</Button>
            <ResultDialog ope="削除" rowNum={rowNum} result={result} open={openDeleteResult} setOpen={setOpenDeleteResult}/>
        </>
    );
};
export default DeleteButton;