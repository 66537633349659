import { useState } from "react";
import Layout from "../templetes/Layout";
import Search from "../assets/Search";
import BasicTable from "../assets/BasicTable";
import { createData, TableData, ColumnData } from "../assets/BasicTable";
import GetButton from "../assets/GetButton";
import ClearButton from "../assets/ClearButton";
import SearchButton from "../assets/SearchButton";

import { Grid } from "@mui/material";

// 初期データ(空)
const initialRows: TableData[] = [
];

type StatisticsColumnsData  = {
    user_id: ColumnData,
    user_name: ColumnData,
    time_stamp: ColumnData,
    latitude: ColumnData,
    longitude: ColumnData,
    building_name: ColumnData
};

const columns : StatisticsColumnsData = {
    user_id: {
        align: "right",
        name: "ユーザID",
        digits: 50
    },
    user_name: {
        align: "right",
        name: "ユーザ名",
        digits: 30
    },
    time_stamp: {
        align: "right",
        name: "時刻",
        digits: 0
    },
    latitude: {
        align: "right",
        name: "緯度",
        digits: 9
    },
    longitude: {
        align: "right",
        name: "経度",
        digits: 9
    },
    building_name: {
        align: "right",
        name: "建物名",
        digits: 30
    }
};

let conut: number = 0;
const url= "https://d0qbwwoh4k.execute-api.ap-northeast-1.amazonaws.com/dev/statistics";

const Statistics = () => {
    // テーブルの行
    const [rows, setRows] = useState<TableData[]>(initialRows);
    const [orgRows, setOrgRows] = useState<TableData[]>(initialRows);

    // 検索バーに入力された文字
    const [userNameSearched, setUserNameSearched] = useState<string>("");
    const [buildingNameSearched, setBuildingNameSearched] = useState<string>("");

    // 追加・クリア・検索ボタン有効/無効設定用
    const [isAuthenticated, setAuthenticated] = useState(true); 

        // const dbRows = [
        //     createData("yamamoto@focus-s.com", "山本太郎", "2023/09/06 10:10:00", 35.62769, 139.72327, "テラサキ第3ビル"),
        //     createData("sasaki@focus-s.com", "佐々木小次郎", "2023/09/06 10:15:00", 35.62769, 139.72327, "テラサキ第2ビル"),
        //     createData("suzukih@focus-s.com", "鈴木宏和", "2023/09/06 10:10:00", 35.62769, 139.72327, "テラサキ第3ビル"),
        // ];
        // const dbRows2 = [
        //     createData("suzukih@focus-s.com", "鈴木宏和", "2023/09/06 10:10:00", 35.62769, 139.72327, "テラサキ第3ビル"),
        //     createData("hawkinson@focus-s.com", "ホーキンソン", "2023/09/06 10:15:00", 35.62769, 139.72327, "テラサキ第2ビル"),
        //     createData("yamamoto@focus-s.com", "山本太郎", "2023/09/06 10:10:00", 35.62769, 139.72327, "テラサキ第3ビル"),
        // ];
        // if (conut % 2 === 0) {
        //     setRows(dbRows);
        //     setOrgRows(dbRows);
        // } else {
        //     setRows(dbRows2);
        //     setOrgRows(dbRows2);
        // }
        // conut++;
        // return;

    return(
        <Layout title="Statistics">
            <Grid container spacing={2} columns={10} position={"relative"}>
                <Grid item xs={4}>
                    <Search
                        id={"user-name-search"}
                        searchRows={rows}
                        searched={userNameSearched}
                        setRows={setRows}
                        setSearched={setUserNameSearched}
                        label="ユーザ名"
                    >
                    </Search>
                </Grid>
                <Grid item xs={1.5}>
                    <SearchButton
                        nameSearched={{user_name: userNameSearched,
                                       building_name: buildingNameSearched}}
                        orgRows={orgRows}
                        setRows={setRows}
                        setCheckedRows={() => {}}
                        isAuthenticated={isAuthenticated}
                    />
                </Grid>
                <Grid item xs={1.5}>
                </Grid>
                <Grid item xs={1.5}>
                    <ClearButton
                        setRows={setRows}
                        setCheckedRows={() => {}}
                        setNameSearched={[setUserNameSearched, setBuildingNameSearched]}
                        isAuthenticated={isAuthenticated}
                        setAuthenticated={setAuthenticated}
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <GetButton
                        url={url}
                        setRows={setRows}
                        setOrgRows={setOrgRows}
                        setCheckedRows={() => {}}
                        setAuthenticated={setAuthenticated}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Search
                        id={"building-name-search"}
                        searchRows={rows}
                        searched={buildingNameSearched}
                        setRows={setRows}
                        setSearched={setBuildingNameSearched}
                        label="建物名"
                    >
                    </Search>
                </Grid>
                <Grid item xs={6}/>
                <Grid item xs={12}>
                    <BasicTable columns={columns} rows={rows} />
                </Grid>
            </Grid>
        </Layout>
    )
};
export default Statistics;