import axios from "axios";
import { useState } from "react";
import { Button } from "@mui/material"
import { API_RESULT } from "./ResultDialog";
import ResultDialog from "./ResultDialog";
import { handleError } from "./CrudError";

const GetButton = (props : any) => {
    const {url,                // アクセス対象URL
           setRows,            // テーブル行更新関数
           setOrgRows,         // 元テーブル行更新関数
           setCheckedRows,     // チェック済みレコード更新関数
           setAuthenticated    // ボタン有効/無効設定更新関数
          } = props;

    // 処理結果
    const [result, setResult] = useState<number>(API_RESULT.SUCCESS);
    // 取得結果ダイアログ用
    const [openResultDialog, setOpenResultDialog] = useState(false);

    // データ参照処理
    const getAllDataFromDB = () => {

        axios.get(url).then((res) => {
            console.log(res);
            console.log(res.status);
            console.log(res.data);
            const dbRows = res.data;
            setCheckedRows([]);
            setRows(dbRows);
            setOrgRows(dbRows);
            setAuthenticated(false);
        }).catch((err) => {
            let result;
            result = handleError(err);
            setResult(result);
            setOpenResultDialog(true);            
        })

    };

    return (
        <>
            <Button fullWidth variant="contained" onClick={getAllDataFromDB}>全件取得</Button>
            <ResultDialog ope="参照" rowNum={0} result={result} open={openResultDialog} setOpen={setOpenResultDialog}/>
        </>
    );
};
export default GetButton;