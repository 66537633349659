import * as React from 'react';
import { Link } from "react-router-dom";
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TableChartIcon from '@mui/icons-material/TableChart';
import PeopleIcon from '@mui/icons-material/People';
import PinDropIcon from '@mui/icons-material/PinDrop';
import HelpIcon from '@mui/icons-material/Help';

const SideMenu: React.FC = () => {

    return (
        <>
            <List component="nav">
                <ListItemButton component={Link} to="/home">
                    <ListItemIcon>
                    <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItemButton>
                <ListItemButton component={Link} to="/statistics">
                    <ListItemIcon>
                    <TableChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Statistics" />
                </ListItemButton>
                <ListItemButton component={Link} to="/user">
                    <ListItemIcon>
                    <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="User" />
                </ListItemButton>
                <ListItemButton component={Link} to="/location">
                    <ListItemIcon>
                    <PinDropIcon />
                    </ListItemIcon>
                    <ListItemText primary="Location" />
                </ListItemButton>
                <ListItemButton component={Link} to="/help">
                    <ListItemIcon>
                    <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" />
                </ListItemButton>
            </List>
        </>
    );
};
export default SideMenu;